//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-60:_8956,_280,_1636,_2480,_3876,_2100,_9204,_1692;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-60')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-60', "_8956,_280,_1636,_2480,_3876,_2100,_9204,_1692");
        }
      }catch (ex) {
        console.error(ex);
      }